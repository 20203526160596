import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import db, { uploadFile } from "../firebase/firebase";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import swal from 'sweetalert';
import Select from 'react-select'



export const Index = () => {

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);

    const [fotoperfil, setFotoperfil] = useState(null)
    const [fotodestacadas, setFotodestacadas] = useState(null)
    const [Pintores, setPintores] = useState([]);
    const [Genero, setGenero] = useState([]);
    const [Estilo, setEstilo] = useState([]);
    const [Tema, setTema] = useState([]);
    const [Medidas, setMedidas] = useState([]);
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState({})
    const [opcionFinal, setopcionFinal] = useState([])
    const [arrayMedidas, setArrayMedidas] = useState([])

    useEffect(() => {

        async function LeerPintor() {

            const docRef = doc(db, "PintoresFamosos", "vLbezfh8QrFsnC0wkAHo");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setPintores(lote)

        }

        LeerPintor();

        async function LeerGenero() {

            const docRef = doc(db, "Genero", "AmhYnSuhAb5gqV3p94C1");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setGenero(lote)

        }

        LeerGenero();

        async function LeerEstilo() {

            const docRef = doc(db, "Estilos", "jdUE6dJdfPtkzfUagjsA");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setEstilo(lote)

        }

        LeerEstilo();


        async function LeerTema() {

            const docRef = doc(db, "Temas", "Yn5cHS03MH3Cs9GxQDaK");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setTema(lote)

        }

        LeerTema();

        async function LeerMedidas() {

            const docRef = doc(db, "Medidas", "PobwbCo7flcyUnNIAhJD");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push({value: element.medida, label: element.medida})
            });
            setOptions(lote)
            let loteDos = new Array
            docSnap.data().categoria.forEach(element => {
                loteDos.push(element)
            });
            setopcionFinal(loteDos)
        }

        LeerMedidas();

    }, [])






    async function funcionfoto() {
        try {
            const result = await uploadFile(fotoperfil)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    async function functionGaleria() {
        try {
            let ArrayTemporal = new Array
            for (let index = 0; index < 4; index++) {
                const result = await uploadFile(fotodestacadas[index]).then((e) => {
                    ArrayTemporal.push(e)
                })
            }
            return ArrayTemporal
        } catch (error) {
            console.error(error)
        }
    }

    async function addProducto(nombre,precio,descuento,famosos,vendido,pintores,genero,estilos,tema,medida,geometria,descripcion,perfil,galeria) {
        const docaddproducto = await addDoc(collection(db, "Producto"), {
            perfil: perfil,
            galeria: galeria,
            nombre: nombre,
            precio: precio,
            descuento: descuento,
            famosos: famosos,
            vendido: vendido,
            pintores: pintores,
            genero: genero,
            estilos: estilos,
            tema: tema,
            medida: medida,
            geometria: geometria,
            descripcion: descripcion,
            visitas:0,
            comprado:0
        });
    }



    const handleSubmit = (e) => {


        e.preventDefault()

        setLoading(true)

        const nombre = e.target.nombre.value;
        const precio = e.target.precio.value;
        let descuento = e.target.descuento.value;
        const famosos = e.target.famosos.value
        const vendido = e.target.vendido.value
        const pintores = e.target.pintores.value
        const genero = e.target.genero.value
        const estilos = e.target.estilos.value
        const tema = e.target.tema.value
        //const medida = e.target.medida.value
        const geometria = e.target.geometria.value
        const descripcion = e.target.descripcion.value;



        if (descuento.length == 0) {
            descuento = ""
        }


        if(
            famosos == "Selecionar" ||
            vendido == "Selecionar" ||
            pintores == "Selecionar" ||
            genero == "Selecionar" ||
            estilos == "Selecionar" ||
            tema == "Selecionar" ||
            geometria == "Selecionar" ||
            selectedOption.length == 0
        ){
            swal({
                text: "faltan campos por seleccionar",
                icon: "error",
                timer: 2000
            })
            setLoading(false)
        }else{

            let medida = new Array
            for (let index = 0; index < selectedOption.length; index++) {
                let holanda = opcionFinal.filter((e) => e.medida == selectedOption[index].value)
                holanda.forEach((e)=>{
                    medida.push(e)
                })

            }
            funcionfoto().then((perfil) => {
                functionGaleria().then((galeria) => {
                    addProducto(nombre,precio,descuento,famosos,vendido,pintores,genero,estilos,tema,medida,geometria,descripcion,perfil,galeria).then((fial) => {
                        window.location.reload(true)
                    })
    
                })
            })
        }





       /* funcionfoto().then((perfil) => {
            functionGaleria().then((galeria) => {
                addProducto(categoria, descuento, galeria, nombre, perfil, precio).then((fial) => {
                    window.location.reload(true)
                })

            })
        })
*/


    }





    return (
        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1 data-text="Cargando..." className="cargando">Cargando...</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="cardd p-4">
                                <h2 className="text-center">Crear</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Precio</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="precio"
                                            name="precio"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descuento</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="descuento"
                                            name="descuento"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Descripción
                                        </label>
                                        <textarea 
                                            class="form-control" 
                                            placeholder="Descripción" 
                                            id="descripcion"
                                            name="descripcion"
                                            required
                                        >

                                        </textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Foto</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            required
                                            onChange={(e) => setFotoperfil(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Fotos Galeria</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            required
                                            onChange={(e) => setFotodestacadas(e.target.files)}
                                            multiple
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Famoso:</label>
                                        <select className="form-select" id="famosos" name="famosos">
                                            <option value="No">
                                                No
                                            </option>
                                            <option value="Si">
                                                Si
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Vendido:</label>
                                        <select className="form-select" id="vendido" name="vendido">
                                            <option value="No">
                                                No
                                            </option>
                                            <option value="Si">
                                                Si
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Pintores:</label>
                                        <select className="form-select" id="pintores" name="pintores">
                                            <option value="Selecionar">
                                                Selecionar:
                                            </option>
                                            {
                                                Pintores.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Genero:</label>
                                        <select className="form-select" id="genero" name="genero" >
                                            <option value="Selecionar">
                                                Selecionar:
                                            </option>
                                            {
                                                Genero.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Estilos:</label>
                                        <select className="form-select" id="estilos" name="estilos">
                                            <option value="Selecionar">
                                                Selecionar:
                                            </option>
                                            {
                                                Estilo.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Tema:</label>
                                        <select className="form-select" id="tema" name="tema">
                                            <option value="Selecionar">
                                                Selecionar:
                                            </option>
                                            {
                                                Tema.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Medida:</label>
                                        <Select isMulti onChange={(e) => setSelectedOption(e)} options={options} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Cuadrado - Horizontañ - Vertical:</label>
                                        <select className="form-select" id="geometria" name="geometria">
                                            <option value="Cuadrado">
                                                Caudrado
                                            </option>
                                            <option value="Horizontal">
                                                Horizontal
                                            </option>
                                            <option value="Vertical">
                                                Vertical
                                            </option>
                                        </select>
                                    </div>
                                    <button type="submit" value="Submit" className="btn btn-primary w-100">
                                        Enviar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
