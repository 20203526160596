import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import db from "../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import swal from 'sweetalert';

export const Estilos = () => {

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);
    const [nombre, setNombre] = useState("");
    const [categorias, setCategorias] = useState([]);
    const [actualizar, setActualizar] = useState(0)

    useEffect(() => {

        async function LeerCategorias() {

            const docRef = doc(db, "Estilos", "jdUE6dJdfPtkzfUagjsA");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setCategorias(lote)
        }

        LeerCategorias();

    }, [actualizar])


    async function Actualizar(array){

        console.log(array)
            
        const docRef = doc(db, "Estilos", "jdUE6dJdfPtkzfUagjsA");

            await updateDoc(docRef, {
            categoria: array
          }).then((listo) => {
            setActualizar(actualizar + 1)
          })


    }


    const handleBorrar = (item) => {
        var arrayFiltrado
        arrayFiltrado = categorias.filter(e => e !== item)

        Actualizar(arrayFiltrado);

    }


    const handleCrear = () => {

        if(nombre.length == 0){
            swal({
                text: "Escribe un Estilo",
                icon: "error",
                timer: 2000
            })
        }else{
            var arrayFiltrado
            var nombrefinal
            nombrefinal = nombre.substring(0, 1).toUpperCase() + nombre.substring(1)
            if(categorias.includes(nombrefinal)){
                swal({
                    text: "Ya Existe el Estilo",
                    icon: "error",
                    timer: 2000
                })
            }else{
                arrayFiltrado = categorias
                arrayFiltrado.push(nombrefinal)
                Actualizar(arrayFiltrado)
                setNombre("")
            }
        }

    }


    return (

        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1>Cargando</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                            <div className="cardd p-3">
                                <h4 className="text-center">
                                Agregar Estilos
                                </h4>
                                <div>
                                    <label>
                                        Nombre:
                                    </label>
                                    <input type="text" className="form-control mb-3" value={nombre} onChange={(e) => setNombre(e.target.value)} name="nombre" id="nombre" />
                                    <button className="btn btn-primary w-100" onClick={handleCrear}>
                                        Crear
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <div className="cardd p-3">
                                <h4 className="text-center mb-3">
                                    Lista de Estilos
                                </h4>
                                <div>
                                    {
                                        categorias.map((e, index) => (

                                            <div className="lista-categorias" key={index}>
                                                <p className="lista-categorias-x m-0" onClick={() => handleBorrar(e)}>
                                                    x
                                                </p>
                                                <p className="m-0 mt-1">
                                                    {e}
                                                </p>
                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}