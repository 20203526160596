import React, { useState, useEffect } from "react";
import Navbar from "../componentes/Navbar";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import db from "../firebase/firebase";
import { useNavigate } from "react-router-dom";



export const Productos = () => {

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);
    const [listaProducto, setListaProducto] = useState([])

    const navigate = useNavigate();

    useEffect(() => {

        async function LeerProductos() {

            const querySnapshot = await getDocs(collection(db, "Producto"));
            let array = new Array
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                array.push({
                    id: doc.id,
                    doc: doc.data()
                })
            });

            setListaProducto(array)

        }

        LeerProductos()

    }, [])


    const handleDelete = (item) => {

        setLoading(true)

        async function Delete(item) {
            await deleteDoc(doc(db, "Producto", `${item}`)).then(
                (eliminado) => {
                    window.location.reload(true)
                }
            )

        }

        Delete(item)

    }


    return (

        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1 data-text="Cargando..." className="cargando">Cargando...</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="cardd p-4">
                                <h3 className="text-center">
                                    Productos
                                </h3>
                                <div className="table-custom">
                                    <div className="head-table">
                                        <div className="numero-table">#</div>
                                        <div className="nombre-table">Nombre</div>
                                        <div className="accion-table">Acción</div>
                                    </div>
                                    <div className="body-table">
                                        {
                                            listaProducto.length == 0 ?
                                                <h4 className="text-center">
                                                    No hay resultados
                                                </h4>
                                                :
                                                listaProducto.map((e, index) => (

                                                    <div key={index}>
                                                        <div className="numero-table-hijo">{index}</div>
                                                        <div className="nombre-table-hijo">{e.doc.nombre}</div>
                                                        <div className="accion-table-hijo d-flex justify-contect-center align-item-center">
                                                            <button onClick={() => handleDelete(e.id)} className="btn btn-danger me-2">
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                            <button onClick={() => navigate(`/Producto/${e.id}`)
                                                        } className="btn btn-success">
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                ))


                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}