import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import db from "../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import swal from 'sweetalert';

export const Medidas = () => {

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);
    const [MedidaOne, setMedidaOne] = useState("");
    const [MedidaTwo, setMedidaTwo] = useState("");
    const [MedidaPrecio, setMedidaPrecio] = useState("");
    const [categorias, setCategorias] = useState([]);
    const [actualizar, setActualizar] = useState(0)

    useEffect(() => {

        async function LeerCategorias() {

            const docRef = doc(db, "Medidas", "PobwbCo7flcyUnNIAhJD");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });
            setCategorias(lote)
        }

        LeerCategorias();

    }, [actualizar])


    async function Actualizar(array){

        console.log(array)
            
        const docRef = doc(db, "Medidas", "PobwbCo7flcyUnNIAhJD");

            await updateDoc(docRef, {
            categoria: array
          }).then((listo) => {
            setActualizar(actualizar + 1)
          })


    }


    const handleBorrar = (item) => {
        var arrayFiltrado
        arrayFiltrado = categorias.filter(e => e.medida !== item)
        console.log(item)
        console.log(arrayFiltrado)

        Actualizar(arrayFiltrado);

    }


    const handleCrear = () => {

        if(MedidaOne.length == 0 || MedidaTwo.length == 0 || MedidaPrecio.length == 0){
            swal({
                text: "Rellena todos Los cuadros",
                icon: "error",
                timer: 2000
            })
        }else{
            var arrayFiltrado
            var nombrefinal
            nombrefinal = `${MedidaOne} x ${MedidaTwo}`
            if(categorias.includes(nombrefinal)){
                swal({
                    text: "Ya Existe esta Medida",
                    icon: "error",
                    timer: 2000
                })
            }else{
                arrayFiltrado = categorias
                arrayFiltrado.push({
                    medida: nombrefinal,
                    precio: MedidaPrecio
                })
                Actualizar(arrayFiltrado)
                setMedidaOne("")
                setMedidaTwo("")
                setMedidaPrecio("")
            }
        }

    }


    return (

        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1>Cargando</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                            <div className="cardd p-3">
                                <h4 className="text-center">
                                Agregar Genero
                                </h4>
                                <div>
                                    <label>
                                        Medida 1:
                                    </label>
                                    <input type="text" className="form-control mb-3" value={MedidaOne} onChange={(e) => setMedidaOne(e.target.value)} name="nombre" id="nombre" />
                                    <label>
                                        Medida 2:
                                    </label>
                                    <input type="text" className="form-control mb-3" value={MedidaTwo} onChange={(e) => setMedidaTwo(e.target.value)} name="nombre" id="nombre" />
                                    <label>
                                        Precio
                                    </label>
                                    <input type="number" className="form-control mb-3" value={MedidaPrecio} onChange={(e) => setMedidaPrecio(e.target.value)} name="precio" id="precio" />
                                    <button className="btn btn-primary w-100" onClick={handleCrear}>
                                        Crear
                                    </button>
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <div className="cardd p-3">
                                <h4 className="text-center mb-3">
                                    Lista de Genero
                                </h4>
                                <div>
                                    {
                                        categorias.map((e, index) => (

                                            <div className="lista-categorias" key={index}>
                                                <p className="lista-categorias-x m-0" onClick={() => handleBorrar(e.medida)}>
                                                    x
                                                </p>
                                                <p className="m-0 mt-1">
                                                    {e.medida} CM
                                                </p>
                                                <p className=" ms-3 m-0 mt-1">
                                                    ${e.precio}
                                                </p>
                                            </div>

                                        ))
                                        }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}