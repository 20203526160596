import React, { useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router'
import { useAuth } from '../authContext/authContext'

export function Login(){

    const navigate = useNavigate()

    const { login } = useAuth();

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const [error, setError] = useState("");

    const handleChange = ({ target: { name, value } }) => {
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(user)
        setError("");
        try {
            await login(user.email, user.password);
            navigate("/Inicio");
        } catch (error) {
            setError(error.message);
        }
    }

    const handleAlerta = (item) => {
        console.log(item)
        if (item === "Firebase: Error (auth/user-not-found).") {
            return (
                <p className='text-danger text-center mt-4'>
                    Correo Errado
                </p>
            );
        }
        if (item === "Firebase: Error (auth/wrong-password).") {
            return(
                <p className='text-danger text-center mt-4'>
                    Contraseña equivocada
                </p>
            )
        }
    }

    return(
        <>
            <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6 mt-5'>
                            <div className='cardd p-4'>
                                <div className='card-body'>
                                    <h3 className='text-center'>Iniciar Sesión</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Correo</label>
                                            <input type="email" className="form-control" onChange={handleChange} name='email' required />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Contraseña</label>
                                            <input type="password" className="form-control" onChange={handleChange} name='password' required />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-100 mt-4">Entrar</button>
                                        {
                                            handleAlerta(error)
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}