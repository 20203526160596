import { Index } from './Vistas/Index'
import { Login } from './Vistas/Login'
import { Categoria } from './Vistas/Categoria'
import { AuthProvider } from "./authContext/authContext"
import { ProtectedRoute } from "./Vistas/ProtectedRoute"
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Productos } from './Vistas/Productos'
import { Producto } from './Vistas/Producto'
import { PintoresFamosos } from './Vistas/PintoresFamosos'
import { Genero } from './Vistas/Genero'
import { Estilos } from './Vistas/Estilos'
import { Temas } from './Vistas/Temas'
import { Medidas } from './Vistas/Medidas'


function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />


          <Route path='/Inicio' element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          } />


          <Route path='/Pintores-famosos' element={
            <ProtectedRoute>
              <PintoresFamosos />
            </ProtectedRoute>
          } />

          <Route path='/Temas' element={
            <ProtectedRoute>
              <Temas />
            </ProtectedRoute>
          } />
          
          <Route path='/Medidas' element={
            <ProtectedRoute>
              <Medidas />
            </ProtectedRoute>
          } />

          <Route path='/Estilos' element={
            <ProtectedRoute>
              <Estilos />
            </ProtectedRoute>
          } />

          <Route path='/Genero' element={
            <ProtectedRoute>
              <Genero />
            </ProtectedRoute>
          } />

          <Route path='/Categoria' element={
            <ProtectedRoute>
              <Categoria />
            </ProtectedRoute>
          } />

          <Route path='/Productos' element={
            <ProtectedRoute>
              <Productos />
            </ProtectedRoute>
          } />

          <Route path='/Producto/:id' element={
            <ProtectedRoute>
              <Producto />
            </ProtectedRoute>
          } />

          <Route path='*' element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          } />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
