import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Navbar from "../componentes/Navbar";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db, { uploadFile } from "../firebase/firebase";
import Select from "react-select";
import swal from 'sweetalert';


export const Producto = () => {

    const params = useParams()

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);

    const [nombre, setnombre] = useState("")
    const [precio, setprecio] = useState("")
    const [descuento, setdescuento] = useState("")
    const [famosos, setfamosos] = useState("")
    const [vendido, setvendido] = useState("")
    const [pintores, setpintores] = useState("")
    const [genero, setgenero] = useState("")
    const [estilos, setestilos] = useState("")
    const [tema, settema] = useState("")
    const [medida, setmedida] = useState("")
    const [geometria, setgeometria] = useState("")
    const [descripcion, setdescripcion] = useState("")
    const [visitas, setvisitas] = useState("")
    const [comprado, setcomprado] = useState("")
    const [FotoFirebase, setFotoFirebase] = useState("")
    const [GaleriaFirebase, setGaleriaFirebase] = useState("")
    const [selectedOption, setSelectedOption] = useState({})
    const [opcionFinal, setopcionFinal] = useState([])
    const [arrayMedidas, setArrayMedidas] = useState([])
    const [options, setOptions] = useState([])


    const [fotoperfil, setFotoperfil] = useState(null)
    const [fotodestacadas, setFotodestacadas] = useState(null)


    const [Pintores, setPintores] = useState([]);
    const [Genero, setGenero] = useState([]);
    const [Estilo, setEstilo] = useState([]);
    const [Tema, setTema] = useState([]);
    const [Medidas, setMedidas] = useState([]);



    useEffect(() => {

        async function LeerDatos() {

            let id = params.id

            const docRef = doc(db, "Producto", `${id}`);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                console.log(docSnap.data())
                setnombre(docSnap.data().nombre)
                setprecio(docSnap.data().precio)
                setfamosos(docSnap.data().famosos)
                setvendido(docSnap.data().vendido)
                setpintores(docSnap.data().pintores)
                setgenero(docSnap.data().genero)
                setestilos(docSnap.data().estilos)
                settema(docSnap.data().tema)
                //setmedida(docSnap.data().medida)
                let medidass = new Array
                docSnap.data().medida.forEach((e)=>{
                    medidass.push({value: e.medida, label:e.medida})
                })
                setSelectedOption(medidass)
                setgeometria(docSnap.data().geometria)
                setdescripcion(docSnap.data().descripcion)
                setvisitas(docSnap.data().visitas)
                setcomprado(docSnap.data().comprado)
                setFotoFirebase(docSnap.data().perfil)
                setGaleriaFirebase(docSnap.data().galeria)



            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        }


        LeerDatos();

        async function LeerPintor() {

            const docRef = doc(db, "PintoresFamosos", "vLbezfh8QrFsnC0wkAHo");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setPintores(lote)

        }

        LeerPintor();

        async function LeerGenero() {

            const docRef = doc(db, "Genero", "AmhYnSuhAb5gqV3p94C1");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setGenero(lote)

        }

        LeerGenero();

        async function LeerEstilo() {

            const docRef = doc(db, "Estilos", "jdUE6dJdfPtkzfUagjsA");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setEstilo(lote)

        }

        LeerEstilo();


        async function LeerTema() {

            const docRef = doc(db, "Temas", "Yn5cHS03MH3Cs9GxQDaK");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push(element)
            });

            setTema(lote)

        }

        LeerTema();

        async function LeerMedidas() {

            const docRef = doc(db, "Medidas", "PobwbCo7flcyUnNIAhJD");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().categoria.forEach(element => {
                lote.push({value: element.medida, label: element.medida})
            });
            setOptions(lote)
            let loteDos = new Array
            docSnap.data().categoria.forEach(element => {
                loteDos.push(element)
            });
            setopcionFinal(loteDos)
        }

        LeerMedidas();

    }, [])




    async function funcionfoto() {
        try {
            const result = await uploadFile(fotoperfil)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    async function functionGaleria() {
        try {
            let ArrayTemporal = new Array
            for (let index = 0; index < 4; index++) {
                const result = await uploadFile(fotodestacadas[index]).then((e) => {
                    ArrayTemporal.push(e)
                })
            }
            return ArrayTemporal
        } catch (error) {
            console.error(error)
        }
    }


    async function Actualizar(nombre, precio, descuento, famosos, vendido, pintores, genero, estilos, tema, medida, geometria, descripcion, perfil, galeria, visitas, comprado) {


        const docRef = doc(db, "Producto", `${params.id}`);

        await updateDoc(docRef, {
            perfil: perfil,
            galeria: galeria,
            nombre: nombre,
            precio: precio,
            descuento: descuento,
            famosos: famosos,
            vendido: vendido,
            pintores: pintores,
            genero: genero,
            estilos: estilos,
            tema: tema,
            medida: medida,
            geometria: geometria,
            descripcion: descripcion,
            visitas: visitas,
            comprado: comprado
        })


    }


    const hanldesubmit = (e) => {

        e.preventDefault()

        setLoading(true)

        let pintoresSelect = e.target.pintores.value
        let generoSelect = e.target.genero.value
        let estilosSelect = e.target.estilos.value
        let temaSelect = e.target.tema.value
        let medidaSelect = e.target.medida.value
        let geometriaSelect = e.target.geometria.value


        if(selectedOption.length == 0){
            swal({
                text: "faltan campos por seleccionar",
                icon: "error",
                timer: 2000
            })
            setLoading(false)
        }else{


            let medida = new Array
            for (let index = 0; index < selectedOption.length; index++) {
                let holanda = opcionFinal.filter((e) => e.medida == selectedOption[index].value)
                holanda.forEach((e)=>{
                    medida.push(e)
                })

            }


            if(fotoperfil == null){
        
                funcionfoto().then((perfil)=>{
                    Actualizar(nombre, precio, descuento, famosos, vendido, pintoresSelect, generoSelect, estilosSelect, temaSelect, medida, geometriaSelect, descripcion, perfil, GaleriaFirebase, visitas, comprado).then((listo)=>{
                        window.location.reload(true)
                    })
                })
    
            }else if(fotodestacadas == null){
    
                functionGaleria().then((galeria)=>{
                    Actualizar(nombre, precio, descuento, famosos, vendido, pintoresSelect, generoSelect, estilosSelect, temaSelect, medida, geometriaSelect, descripcion, FotoFirebase, galeria, visitas, comprado).then((listo)=>{
                        window.location.reload(true)
                    })
                })
    
            }else{
    
                funcionfoto().then((perfil)=>{
    
                    functionGaleria().then((galeria)=>{
                        Actualizar(nombre, precio, descuento, famosos, vendido, pintoresSelect, generoSelect, estilosSelect, temaSelect, medida, geometriaSelect, descripcion, perfil, galeria, visitas, comprado).then((listo)=>{
                            window.location.reload(true)
                        })
                    })
    
                })
    
            }


        }




    }


    const handleGeometria = () => {
        if (geometria == "Caudrado") {
            return (
                <>
                    <option value="Horizontal">
                        Horizontal
                    </option>
                    <option value="Vertical">
                        Vertical
                    </option>
                </>
            )
        } else if (geometria == "Horizontal") {
            return (
                <>
                    <option value="Caudrado">
                        Caudrado
                    </option>
                    <option value="Vertical">
                        Vertical
                    </option>
                </>
            )
        } else {
            return (
                <>
                    <option value="Caudrado">
                        Caudrado
                    </option>
                    <option value="Horizontal">
                        Horizontal
                    </option>
                </>
            )
        }
    }



    return (
        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1 data-text="Cargando..." className="cargando">Cargando...</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="cardd p-4">
                                <h3 className="text-center">
                                    Editar Producto
                                </h3>
                                <form onSubmit={hanldesubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            required
                                            value={nombre}
                                            onChange={(e) => setnombre(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Precio</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="precio"
                                            name="precio"
                                            required
                                            value={precio}
                                            onChange={(e) => setprecio(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descuento</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="descuento"
                                            name="descuento"
                                            value={descuento}
                                            onChange={(e) => setdescuento(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Descripción
                                        </label>
                                        <textarea
                                            className="form-control"
                                            placeholder="Descripción"
                                            id="descripcion"
                                            name="descripcion"
                                            required
                                            value={descripcion}
                                            onChange={(e) => setdescripcion(e.target.value)}
                                        >

                                        </textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Foto</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setFotoperfil(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Fotos Galeria</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setFotodestacadas(e.target.files)}
                                            multiple
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Famoso:</label>
                                        <select className="form-select" id="famosos" name="famosos">
                                            <option value={famosos}>
                                                {famosos}
                                            </option>
                                            {
                                                famosos == "No" ?
                                                    <option value="Si">
                                                        Si
                                                    </option>
                                                    :
                                                    <option value="No">
                                                        No
                                                    </option>
                                            }

                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Vendido:</label>
                                        <select className="form-select" id="vendido" name="vendido">
                                            <option value={vendido}>
                                                {vendido}
                                            </option>
                                            {
                                                famosos == "No" ?
                                                    <option value="Si">
                                                        Si
                                                    </option>
                                                    :
                                                    <option value="No">
                                                        No
                                                    </option>
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Pintores:</label>
                                        <select className="form-select" id="pintores" name="pintores">
                                            <option value={pintores}>
                                                {pintores}
                                            </option>
                                            {
                                                Pintores.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Genero:</label>
                                        <select className="form-select" id="genero" name="genero" >
                                            <option value={genero}>
                                                {genero}
                                            </option>
                                            {
                                                Genero.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Estilos:</label>
                                        <select className="form-select" id="estilos" name="estilos">
                                            <option value={estilos}>
                                                {estilos}
                                            </option>
                                            {
                                                Estilo.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Tema:</label>
                                        <select className="form-select" id="tema" name="tema">
                                            <option value={tema}>
                                                {tema}
                                            </option>
                                            {
                                                Tema.map((e) =>
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Medida:</label>
                                        <Select isMulti value={selectedOption} onChange={(e) => setSelectedOption(e)} options={options} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Cuadrado - Horizontañ - Vertical:</label>
                                        <select className="form-select" id="geometria" name="geometria">

                                            <option value={geometria}>
                                                {geometria}
                                            </option>
                                            {
                                                handleGeometria()
                                            }
                                        </select>
                                    </div>
                                    <button type="submit" value="Submit" className="btn btn-primary w-100">
                                        Enviar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}