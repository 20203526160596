// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0mPYebyENjOayGB1NPiPM-i6I3nFLDHo",
  authDomain: "cuadrosreact.firebaseapp.com",
  projectId: "cuadrosreact",
  storageBucket: "cuadrosreact.appspot.com",
  messagingSenderId: "565028271152",
  appId: "1:565028271152:web:ee21e57670d83db34929f5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app)
export async function uploadFile(file){
  const storageRef = ref(storage, v4())
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef)
  return url;
}

export default db;
export const auth = getAuth(app);